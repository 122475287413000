<template>
  <!--begin::Layout-->
  <div v-if="patient" class="d-flex flex-column flex-lg-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
      <Details
        v-if="tabs.selected === 'Details'"
        :patient="patient"
        @edit="openEditDetails"
        @save-notes="saveNotes"
      ></Details>
      <Files
        v-if="tabs.selected === 'Files'"
        :patient="patient"
        :patient-documents="patientDocuments"
        @files-uploaded="fetchPatientsDocuments"
      ></Files>
    </div>
    <!--end::Content-->

    <!--begin::Sidebar-->
    <div
      class="
        flex-column flex-lg-row-auto
        w-lg-250px w-xl-300px
        mb-10
        order-1 order-lg-2
      "
    >
      <Summary
        :patient="patient"
        :tabs="tabs"
        @select="selectTab"
        @delete-patient="deletePatient"
      ></Summary>
    </div>
    <!--end::Sidebar-->

    <NewPatient
      name="new-patient-modal"
      v-model="isNewPatientModalOpen"
      @cancel="closeEditDetails"
      @confirm="onPatientUpdated"
    >
      <template v-slot:title>Edit prospect</template>
    </NewPatient>
  </div>
  <!--end::Layout-->
</template>

<script>
import Details from "@/components/patients/Details.vue";
import Files from "@/components/patients/Files.vue";
import Summary from "@/components/patients/Summary.vue";
import NewPatient from "@/components/modals/patients/NewPatient.vue";
import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";

export default {
  components: {
    Details,
    Files,
    Summary,
    NewPatient,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isEditingDetails: false,
      tabs: {
        selected: "Details",
        items: ["Details", "Files"],
      },
      patient: null,
      patientDocuments: [],
      patientOperations: [],
      isNewPatientModalOpen: false,
    };
  },
  mounted() {
    this.fetchPatient();
    setCurrentPageBreadcrumbs("View Prospect", ["Prospects"]);
    this.$nextTick(() => {
      StickyComponent.reInitialization();
    });
  },
  methods: {
    openEditDetails() {
      this.$vfm.show("new-patient-modal", this.patient);
    },
    closeEditDetails() {
      this.isNewPatientModalOpen = false;
    },
    onPatientUpdated(updatedPatient) {
      this.$vfm.hide("new-patient-modal");
      this.fetchPatient();
    },
    fetchPatient() {
      ApiService.get("/patients", this.$route.params.id)
        .then(({ data: patient }) => {
          console.log(patient);
          this.patient = patient;
          this.fetchPatientsDocuments();

          ApiService.query(`/operations?patient=${this.patient.id}`)
            .then(({ data }) => {
              console.log(data);
              this.patientOperations = data.results;
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          // this.isTableLoading = false;
        });
    },
    fetchPatientsDocuments() {
      ApiService.query(`/patients/${this.patient.id}/documents`)
        .then(({ data }) => {
          console.log(data);
          this.patientDocuments = data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    selectTab(tab) {
      this.tabs.selected = tab;
    },
    saveNotes(notes) {
      const config = {
        responseToast: {
          text: "Notes saved successfully.",
        },
      };
      ApiService.update("/patients", this.patient.id, { notes }, config).then(
        () => {
          this.toast.success("Notes saved successfully");
        }
      );
    },
    addFile() {
      //TODO
      return;
    },
    deletePatient() {
      Swal.fire({
        text: `Delete ${this.patient.firstname} ${this.patient.lastname}?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-primary",
        },
      }).then(({ isConfirmed }) => {
        if (!isConfirmed) return;

        const config = {
          responseToast: {
            text: "Prospect deleted successfully.",
          },
        };
        ApiService.delete(`patients/${this.patient.id}`).then(() => {
          this.$router.push({ name: "prospects" });
        });
      });
    },
  },
};
</script>
